export default {
    // 园区管理
    insights: {
        // 节点
        nodes: {
            current_node: "当前节点",
            selection_node: "选择节点",
            title: "园区管理",
            manage: "节点管理",

            // 筛选
            sort_btn_start: "开始排序",
            sort_btn_stop: "结束排序",

            // 表单
            add: "添加节点",
            edit: "编辑节点",
            dashboard: "面板",
            name: "名称",
            remark: "备注",
            dashboard_placeholder: "请选择面板",
            name_placeholder: "请输入名称",
            remark_placeholder: "请输入备注",
            search_placeholder: "请输入关键词搜索",

            // 设置
            delete: "删除",
            confirm_delete: "是否确认删除该节点（该操作不可恢复）?",
            confirm_delete_prefix: "是否确认删除",
            confirm_delete_suffix: "节点（该操作不可恢复）?",
            upload_template: "上传模板",
            download_template: "下载模板",
            empty_group: "还没有任何分组",
            to_create: "创建分组",

            // 列表
            untitled: "未命名",
            empty_list: "没有任何节点",

            // 操作
            prop: "属性",
            chart: "图表",
            params: "参数",
            cron: "任务",
            device: "设备",
            node_template: "模板",
            geo: "地理",
            settings: "设置",
            irrigate: "灌溉任务",
        },
        // 面板
        dashboard: {
            current_dashboard: "当前园区",
            selection_dashboard: "选择园区",
            drawer_title: "面板管理",

            dashboard: "面板",
            add: "创建面板",
            edit: "编辑",
            delete: "删除",
            confirm_delete: "确定要删除该面板吗（该操作不可恢复）?",
            add_dashboard: "添加面板",

            search_placeholder: "请输入关键词搜索",
            dashboards: "面板",
            nodes: "节点",

            // 表单
            name: "名称",
            name_placeholder: "请输入名称",
            remark: "备注",
            remark_placeholder: "请输入备注",

            auth: "分配管理人员",
            grant: "添加授权",
            select_user: "请选择",
            unknown: "未知",
            remove: "移除",
            confirm_remove: "确定要移除该成员权限吗？",
            invalid: "<未绑定手机>",
        },
        // 弹出面板
        panel: {
            add: "新增",
            edit: "编辑",
            start: "启动",
            stop: "停止",
            del: "删除",
            tips: "提示",
            copy: "复制并新建",
            enabled: "启用",
            disabled: "禁用",
            action: "操作",
            note: "备注",
            ok: "确定",
            cancel: "取消",
            // 属性
            prop: {
                add: "新增属性",
                edit: "编辑属性",
                attribute_type: "属性类型",
                attribute_type_placeholder: "请选择属性类型",
                attribute_name: "属性名",
                attribute_name_placeholder: "请输入属性名",
                attribute_name_exist: "属性名已存在",
                attribute_display_name: "显示名",
                attribute_display_name_placeholder: "请输入显示名",
                attribute_value: "属性值",
                attribute_value_placeholder: "请输入属性值",
                data_type: "数据类型",
                view_mode: "展示类型",
                view_mode_placeholder: "请选择展示类型",
                view_meta: "展示设置",
                view_meta_min: "最小值",
                view_meta_max: "最大值",
                view_meta_fitMin: "参考下限",
                view_meta_fitMax: "参考上限",
                view_meta_required: "请设置参考上下限及最大最小值",
                attribute_type_input: "参数",
                attribute_type_switch: "开关",
                attribute_type_camera: "相机",
                attribute_type_radio: "状态",
                unit: "单位",
                unit_placeholder: "请输入单位",
                icon: "图标",
                icon_placeholder: "选择图标",
                icon_search_placeholder: "搜索图标",
                panel_display: "面板展示",
                allow_operation: "允许操作",
                data_persistence: "数据持久化",
                robotIp: "机器人（IP地址）",
                cameraIp: "相机（推流地址）",
                del_message: "您确定要删除吗? 此操作将无法撤消。",
                setting: "数据设置",
                empty_property: "暂不存在任何属性",

                custom: "自定义界面",
                sunlight_warning: "若启用此属性，请务必设置地理信息。",

                group: "分组",
                group_placeholder: "请选择分组",
            },
            // 参数
            parameter: {
                add: "添加参数",
                parameter: "参数",
                name: "参数名",
                alias: "参数别名",
                type: "参数类型",
                types: {
                    string: "文本",
                    number: "数字",
                    time: "时间",
                },
                value: "参数值",
                error_name: "请输入参数名",
                error_alias: "请输入参数别名",
                error_value_number: "请输入正确的数字参数值",
                error_value_text: "请输入正确的文本参数值",
                error_value_time: "请输入正确的时间参数值",
                save_success: "参数保存成功",
                operation_success: "参数操作成功",
                delete_success: "参数删除成功",
                del_message: "确定要删除该参数吗？该操作不可撤销。",
                launch_success: "参数下发成功",
                sure: "确定下发所有配置参数吗？",
                empty: "暂无参数",
                content:
                    "!!! 没有连接设备，无法立即下发，提交的改动会保存在服务器端，服务器在设备连接后会自动下发提交的改动。",

                // 状态组件
                status: {
                    close: "关",
                    open: "开",
                    stop: "停",
                },
            },
            // 任务
            tasks: {
                add: "创建任务",
                task: "任务",
                name: "任务名",
                type: "定时语法类型",
                daily: "定时设置（每日）",
                basic: "基本",
                advanced: "高级",
                cronExps: "定时表达式",
                desc: "描述",
                desc_placeholder: "请输入描述",
                add_micro_task: "添加微任务",
                cron_tip: "基础模式下，每个微任务将每天重复执行；高级模式下，每个微任务将按照定时表达式执行",
                trigger: "立即灌溉",
                empty: "暂无任务",
                callback: "操作回调",
                errorCronExps: "Please enter the cron expression",
                errorCallback: "请输入操作回调函数",
                del_message: "确定要删除该定时任务吗？该操作不可撤销。",
            },
            // cron 表达式
            cron: {
                Seconds: {
                    name: "秒",
                    every: "每一秒钟",
                    interval: ["每隔", "秒执行 从", "秒开始"],
                    specific: "具体秒数(可多选)",
                    cycle: ["周期从", "到", "秒"],
                },
                Minutes: {
                    name: "分",
                    every: "每一分钟",
                    interval: ["每隔", "分执行 从", "分开始"],
                    specific: "具体分钟数(可多选)",
                    cycle: ["周期从", "到", "分"],
                },
                Hours: {
                    name: "时",
                    every: "每一小时",
                    interval: ["每隔", "小时执行 从", "小时开始"],
                    specific: "具体小时数(可多选)",
                    cycle: ["周期从", "到", "小时"],
                },
                Day: {
                    name: "天",
                    every: "每一天",
                    intervalWeek: ["每隔", "周执行 从", "开始"],
                    intervalDay: ["每隔", "天执行 从", "天开始"],
                    specificWeek: "具体星期几(可多选)",
                    specificDay: "具体天数(可多选)",
                    lastDay: "在这个月的最后一天",
                    lastWeekday: "在这个月的最后一个工作日",
                    lastWeek: ["在这个月的最后一个"],
                    beforeEndMonth: ["在本月底前", "天"],
                    nearestWeekday: ["最近的工作日（周一至周五）至本月", "日"],
                    someWeekday: ["在这个月的第", "个"],
                },
                Week: ["天", "一", "二", "三", "四", "五", "六"].map((val) => "星期" + val),
                Month: {
                    name: "月",
                    every: "每一月",
                    interval: ["每隔", "月执行 从", "月开始"],
                    specific: "具体月数(可多选)",
                    cycle: ["从", "到", "月之间的每个月"],
                },
            },
            // 设备
            device: {
                current_devices: "当前设备",
                device_history: "历史设备",

                device_id: "设备编号",
                device_alias: "设备别名",
                device_network: "联网类型",
                device_status: "状态",
                operation: "操作",

                unbind: "解绑",
                program: "编程",

                bind_success: "绑定成功",
                bind_device: "绑定设备",
                bind_btn: "绑定",
                bind_placeholder: "请输入要绑定的设备编号",

                unbind_message: "您确定要解绑该设备吗?",
                unbind_success: "解绑成功",
                rebind: "重新绑定",
                no_history: "暂无历史设备",

                online: "在线",
                offline: "离线",

                script_placeholder: "请输入脚本名称",
                script_library: "脚本库",
                script_library_logs: "历史脚本库",
                more: "更多",

                device_name: "硬件名称",
                hardware_version: "硬件版本",
                firmware_version: "固件版本",
                network_type: "网络类型",
                current_memory: "当前内存",
                history_memory: "历史内存",
                script_capacity: "脚本容量",
                firmware_update: "固件更新",
                copy_success: "复制成功",
                del_script: "删除脚本",
                del_success: "删除成功",
                confirm_label: "确认",

                file_select: "选择文件",

                save: "保存",
                rollback: "回滚",
                script_empty: "脚本内容不能为空",
                save_success: "保存成功",
                rollback_success: "回滚成功",
                install_success: "安装成功",
                add_script: "添加脚本",
                add: "添加",
                tips: "提示",
                script_name_enter: "请填写脚本名称",
                script_name_validate: "脚本名称不能为空",
                script_name_exist: "脚本名称已存在",
                tabs_alert: "请从左侧栏选择需要查看的脚本",

                device_info: "设备信息",
                copy_device_id: "设备ID",
                install: "安装",

                generate_code: "智能生成代码",
                loading_code_templates: "加载代码模版",
                save_code: "保存为模版",
                save_script: "保存",
                edit_success: "修改成功",
                enter_code_name: "请输入模版名称",
                enter_group_name: "请输入分组名称",
                select_group: "请选择分组",
                code_templates: "代码模版",
                add_group: "添加分组",
                general_templates: "通用模版",
                custom_templates: "自定义模版",
                custom_group: "自定义分组",
                group: "分组",
                quick_Add: "保存为代码模版",
                change_name: "修改脚本名称",
                save_directly: "直接保存",
                no_code: "没有对应代码模块",
                group_name_empty: "分组名称不能为空",
                code_name_validate: "脚本名称不能为空",

                search_xterm: "搜索",
            },
            // 图表
            chart: {
                start: "开始时间",
                end: "结束时间",
                to: "至",
                last_7_days: "最近7日",
                last_3_days: "最近3日",
                yesterday: "昨日",
                today: "今日",
                last_month: "最近1个月",
                last_3_months: "最近3个月",
                last_6_months: "最近6个月",
                last_24_hours: "24小时",
                search: "查询",
                download: "导出CSV",
                date_error: "请选择开始时间或结束时间",
                data_error: "请选择下载数据",
            },
            // 模板
            template: {
                import: "导入",
                import_confirm_title: "导入模板",
                import_tips:
                    "此项将对现有节点配置项进行变更，可能导致所有功能异常，非技术人员请不要操作。请确认已知晓，此操作将不可撤销！",
                import_mode: "导入途径",
                import_mode_template: "从模板导入",
                import_mode_file: "从本地导入",
                select_template: "选择模板",
                select: "- 选择 -",
                select_file: "选择文件",
                template_upload_tip: "拖拽文件至此或点击上传",
                template_upload_limit: "请上传JSON模板文件",
                template_module: "模块",
                module: "导入模块",
                modules: {
                    properties: "属性",
                    parameters: "参数",
                    crontabs: "任务",
                },
                mode: "导入模式",
                merge: "合并",
                coverage: "覆盖",
                reset: "重置",

                export: "导出",
                export_mode: "导出途径",
                export_mode_template: "保存为模板",
                export_mode_file: "导出到本地",
                template_group: "模板分组",
                template_name: "模板名称",
                file_name: "模板名称",
                download: "下载模板",
                save: "保存模板",

                add_group: "添加分组",
                group_config: "分组配置",
                all: "全部",
                group_name: "分组名称",
                temp_placeholder: "请输入模板名称",
                name_error: "模板名称不能为空",
                edit_group: "编辑分组",
                del_group: "删除分组",

                // table
                from: "来源",
                updated_at: "更新时间",
                temp_search_placeholder: "请输入模板名称进行搜索",
                operation: "操作",
                template_type: "模板类型",
                public_group: "公共分组",
                private_group: "私有分组",
                public_template: "公共模板",
                private_template: "私有模板",
                edit_template: "编辑模板",
                view_template: "查看模板",
                group: "分组",
                creator: "创建者",
                organization: "组织",
                template_content: "模板内容",
                empty_group: "暂无分组",
                group_switch: "分组切换",
                create_template: "创建模板",
            },
            // 设置
            setting: {
                // 地理
                locate: "定位至这里",
                cancel: "取消",
                geo_reset: "确认重置地理位置？",
                coordinate: "坐标",
                current_coordinate: "当前坐标",

                // 分组
                group: "分组",
                no_group: "未分组",
                search_node: "搜索节点",
                setting: "设置",
                empty_node: "当前分组没有任何节点",
                group_name_error: "分组名称不能为空",
                group_name_placeholder: "请输入分组名称",
                group_empty: "当前没有任何分组",

                no_match: "没有匹配的节点",
                other_dashboard_match: "其他园区匹配到的节点",
                dashboard: "园区",

                base: "基础设置",
                plugin_extend: "插件扩展",

                // sigrow
                sigrow_api_placeholder: "请输入Sigrow API Key（非必填）",
                sigrow_central_placeholder: "请输入Sigrow Central ID",
            },
            // 灌溉任务
            irrigate: {
                alert: "新任务正在同步中...",
                task_list: "任务列表",
                add_task: "创建任务",

                // 列表字段
                index: "序号",
                name: "任务名称",
                type: "任务类型",
                duration: "任务时长",
                irrigate_duration: "灌溉时长(单区)",
                status: "任务状态",
                operation: "操作",

                timing: "混肥-定时灌溉",
                integral: "混肥-光积分灌溉",
                fertilizer: "储存罐-配肥",
                storage_timing: "储存罐-定时灌溉",
                storage_integral: "储存罐-光积分灌溉",

                trigger: "立即灌溉",
                edit: "编辑",
                delete: "删除",
                delete_confirm: "确认删除该任务？",
                copy: "复制",
                trigger_success: "触发成功",
                trigger_failed: "触发失败",
                enabled_failed: "操作失败",
                // 有一个任务存在冲突
                enabled_conflict: "与任务【{name}】存在冲突,冲突时间【{time}】",
                self_conflict: "任务时间存在冲突,冲突时间【{time}】",

                // 弹窗内容
                dialog_edit_title: "编辑任务",
                dialog_add_title: "创建任务",
                dialog_copy_title: "复制任务",
                per_duration: "单次执行时长",
                name_placeholder: "请输入任务名称",
                // 定时灌溉
                timing_params: "定时灌溉参数",
                time: "执行时间(每日)",
                time_placeholder: "请选择执行时间",
                duration_text: "{time}",
                irrigate_to_drainage: "灌溉至排液",
                minute: "分钟",
                // 公共参数
                common_params: "公共参数",
                target_ec: "目标EC",
                target_ec_placeholder: "请输入目标EC",
                ec_pump_speed_ratio: "EC泵转速比",
                ec_pump_speed_ratio_full: "B泵转速/A泵转速",
                ec_pump_speed_ratio_placeholder: "请输入EC泵速比",
                ph_pump_speed: "pH泵转速",
                ph_pump_speed_placeholder: "请输入pH泵转速",
                ph_pump_speed_full: "",
                irrigate_area: "轮灌区域",
                irrigate_area_full: "",
                io_text: "{io}号阀",
                add_io: "添加",
                // 积分灌溉
                integral_params: "积分灌溉参数",
                integral_duration: "光积分时段",
                integral_duration_text: "至",
                integral_threshold: "光积分阈值",
                integral_threshold_placeholder: "请输入光积分阈值",
                max_integral_interval: "最大积分间隔",
                first_irrigate_ec: "首次灌溉目标EC",
                first_irrigate_ec_placeholder: "请输入首次灌溉目标EC",
                first_irrigate_duration: "首次灌溉时长",
                first_irrigate_duration_placeholder: "请输入首次灌溉时长",
                // 配肥
                fertilizer_params: "配肥参数",
                fertilizer_type: "配肥类型",
                timing_fertilizer: "定时配肥",
                fertilizer_time: "配肥时间(每日)",
                fertilizer_duration: "配肥时长",
                fertilizer_duration_placeholder: "请选择配肥时长",
                liquid_level_fertilizer: "液位配肥",
                start_liquid_level: "启动配肥液位",
                stop_liquid_level: "停止配肥液位",
                meter: "米",
            },
        },
        // 实时监控
        overview: {
            basic: "基础信息",
            timelapse: "时光机",
            task: "定时任务",
            control: "批量控制",
            follow: "关注指标",
            chart: "自定义图表",
            irrigate: "灌溉助手",
            stomata_camera: "气孔相机",

            indicator: {
                title: "关键指标",
                chart: "指标图表",
                empty: "当前节点没有数据",

                par: "光合有效辐射",
                par_integral: "光合积分量",
                co2: "二氧化碳浓度",
                air_temperature: "空气温度",
                dew_point: "露点温度",
                frost_point: "霜点温度",
                air_humidity: "空气湿度",
                absolute_humidity: "绝对湿度",
                VPD: "VPD",
                vpd: "VPD",
                soil_ec: "土壤EC",
                soil_temperature: "土壤温度",
                soil_humidity: "土壤湿度",
                soil_water: "土壤含水量",
                pore_ec: "土壤poreEC",
                discharge_ratio: "排液比",
                drainage_ec: "排液EC",
                radiation: "太阳总辐射",
            },
            indicator_types: {
                light: "光",
                air: "空气",
                heat: "热",
                water: "水",
                soil: "土壤",
                root: "根系",
                plant: "植物",
            },

            outdoor: {
                title: "室外环境",
                no_geo: "尚未设置地理信息",
                click_to_configure: "点击配置",
                next_24_hour: "未来24小时逐小时预报",
                OAT: "室外温度",
                Td: "露点温度",
                RH: "相对湿度",
                HPD: "小时降水",
                Pa: "大气压强",
                WS: "风速",
                wind_direction: "风向",
                cloud: "云量",
            },

            device: {
                title: "设备绑定",
                advanced_configuration: "高级配置",
                empty_device: "暂未绑定任何设备",
            },

            controls: {
                global: "全局控制",
                global_warning: "请谨慎操作",
                untitled: "未命名",
                selected_all: "组操作",
                batch_control: "批量控制",
                empty: "暂未添加任何开关",
            },
            tasks: {
                title: "当前任务",
                name_edit: "编辑任务名称",
                more: "等{count}个",
                status: "状态",
                on: "启用中",
                off: "停用中",
                trigger: "执行",
                coding: "编辑",
                template: "模版",
                desc: "任务描述",
                name_placeholder: "请输入任务名称",
                cron_tip: "可通过微任务同时设置多个执行规则",
                empty_text: "暂未添加任务",
                cron_title: "定时规则设置",
                untitled: "未分组",
                success: "任务下发成功",
                unknown_error: "未知异常",
                all: "全部",

                save_as_temp: "保存为模版",
                empty_template: "暂无模版",
                templates: {
                    title: "预设任务",
                    public: "公共模板",
                    user: "私有模板",
                    no_group: "未分组",
                    insert: "导入",
                    description: "任务描述",
                    cron_exps: "定时规则",
                    no_template: "暂无模板",
                    to_create: "去创建模板",
                    join_group: "加入分组",
                    duplicate_names: "重复的定时任务名称",
                    enter_name: "请输入定时任务名称",
                },
                logs: {
                    title: "执行日志",
                    log_status: "状态",
                    log_time: "时间",
                    log_detail: "详情",
                    task_name: "任务名",
                    empty_log: "无近期执行记录",
                    empty: "暂无任何执行日志",
                    execution_duration: "执行时长",
                    remark: "备注",
                    edit_remark: "编辑备注",

                    start: "开始日期",
                    end: "结束日期",

                    SUCCESS: "成功",
                    FAILED: "失败",
                    unknown: "未知",
                    undefined: "未知",
                },
            },

            irrigates: {
                // tabs
                summary: "灌溉态势",
                crop_balance: "作物均衡",
                water_change: "含水量变化",
                sidebar_title: "实时数据",
                sidebar_null: "暂无数据",

                // summary
                irrigate: "灌溉平衡",
                drain: "排液详情",
                discharge_ratio: "灌溉排液比",
                irrigation_efficiency: "灌溉光照比",

                // 累计值
                total_irrigate: "累计灌溉",
                // 瞬时值
                instant_irrigate: "瞬时灌溉",

                // crop balance
                energy: "作物能量平衡",
                biomass_accumulation: "生物积质累量",
            },

            preset: {
                title: "指标推荐预设",
                preset_plan: "预设方案",
            },

            // 气孔相机
            stomata: {
                dli: "日光积分",
                g_water: "绝对湿度",
                vbat: "电池百分比",
                par: "PAR",
                temp: "气温",
                humid: "相对湿度",
                t_dew: "露点温度",
                t_leaf: "干叶温度",
                vpd: "VPD",
                history: "历史数据",

                prev: "上一张",
                next: "下一张",
                auto: "自动播放",
                stop: "停止播放",
                distance: "播放间隔",
                distance_placeholder: "请输入间隔时间",
                next_soon: "即将播放下一张..",
                current_time: "当前时间",
                prev_time: "上一张时间",
                next_time: "下一张时间",
                empty: "当前节点没有照片数据",
                camera_snapshot: "相机快照",
                camera_config: "相机参数",
                dry_leaf_config: "干叶设置",
                edit_dry_leaf_position: "编辑干叶位置",
                point_name_required: "请输入点名称",
                point_name: "点名称",
                point_x_required: "请输入X坐标",
                point_x: "X",
                point_y_required: "请输入Y坐标",
                point_y: "Y",
                camera_placeholder: "请输入相机配置",

                // 原图
                original: "原图",
                // 温度
                temperature: "温度",
                // 气孔
                stomata: "气孔",
                temperature_error: "异常",
            },
        },
        conf: {
            group: "节点分组",
            create_node: "创建节点",
            create_dashboard: "创建园区",
            group_manage: "分组管理",
            permissions: "权限授予",
            edit_dashboard: "编辑面板",
            delete_dashboard: "删除面板",
            no_node: "当前分组没有任何节点",
            duplicate_group_names: "重复的分组名",
            dashboard_switch: "园区切换",

            total: "(<b>{total}</b>个节点)",
        },
        manage: {
            title: "节点管理",
        },
        // 公共分组组件
        group: {
            add: "添加",
            search_placeholder: "输入分组名称进行搜索",
            title_placeholder: "请输入分组名称",
            title: "分组名",
            operation: "操作",
        },
    },
};
