import { $io, $titan2 } from "@/utils/api";

// 更新资料
export function updateInfo(data) {
    return $io().put(`/api/user/info`, data);
}

// 更新密码
export function updatePassword(data) {
    return $io().put(`/api/user/password`, data);
}

// 获取用户所在组织
export function getUserOrganizations() {
    return $io().get(`/api/user/organizations`);
}

// 获取用户资料
export function getUserInfo() {
    return $io().get(`/api/user/info`, {
        params: {
            fields: "*",
        },
    });
}

// =============== titan2 ===============

// 获取用户列表
export function getUserList(params) {
    return $titan2().get(`/user`, {
        params,
    });
}
export function getMeta(key) {
    return $titan2().get(`/user/meta`, {
        params: {
            key,
        },
    });
}

export function setMeta(key, val) {
    return $titan2().post(`/user/meta/${key}`, { val });
}

// 获取当前用户组织
export function getCurrentOrganization() {
    return $titan2().get(`/user/organization`);
}

// 切换组织
export function switchOrg(id) {
    return $titan2().put(`/user/organization`, {
        organization_id: id,
    });
}

// 获取个人信息
export function getProfile() {
    return $titan2().get(`/user/info`);
}

// 更新个人信息
export function updateProfile(data) {
    return $titan2().put(`/user/info`, data);
}

// 更新偏好设置
export function updatePreference(data) {
    return $titan2().put(`/user/preference`, data);
}

// 更新用户密码
export function updateProfilePassword(data) {
    return $titan2().put(`/user/password`, data);
}

// 获取用户所有组织
export function getAllOrganization() {
    return $titan2().get(`/organization`);
}

// 获取当前用户操作日志
export function getActionLog(params) {
    return $titan2().get(`/user/log/action`, { params });
}

// 获取当前用户登录日志
export function getLoginLog(params) {
    return $titan2().get(`/user/log/login`, { params });
}
