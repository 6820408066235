export default {
    // 监控大盘
    monitor: {
        title: "监控大盘",
        add_dashboard: "添加大盘",
        edit_dashboard: "编辑大盘",
        dashboard_name: "大盘名称",
        dashboard_placeholder: "请输入大盘名称",
        dashboard_name_required: "请输入大盘名称",
        delete_dashboard_confirm: "确定删除该大盘吗？",
        delete_dashboard: "删除大盘",
        delete_chart: "删除图表",
        delete_chart_confirm: "确定删除此图表吗？",

        copy_chart: "复制图表",
        copy_chart_new_name: "新复制图表的名称",

        update_time: "更新时间",
        create_time: "创建时间",
        minute: "分钟",
        second: "秒",
        cross_node: "跨节点",

        actions: {
            edit: "编辑",
            delete: "删除",
            copy_success: "复制成功",
            refresh: "刷新",
            copy: "复制",
            export: "导出",
            fullscreen: "大图",
            more: "更多",
            public: "设为公共大盘",
            public_tooltip: "组织下所有成员可见",
            private: "设为私有大盘",
            private_tooltip: "仅自己可见",
        },

        // 详情
        add_view: "添加图表",
        // 编辑布局
        edit_layout: "编辑布局",
        save_layout: "保存布局",

        times: {
            "1h": "1小时",
            "6h": "6小时",
            "12h": "12小时",
            "1d": "1天",
            "3d": "3天",
            "7d": "7天",
            custom: "自定义",
        },

        // 自动刷新
        auto_refresh: "自动刷新",
        by_line: "每行展示{count}",
        no_data: "暂无数据",

        // drawer
        edit_view: "编辑图表",
        chart_types: {
            line: "折线图",
            bar: "柱状图",
            pie: "饼图",
            area: "面积图",
            dashboard: "仪表盘",
            radar: "雷达图",
        },
        tabs: {
            data: "数据选项",
            chart: "图表选项",
        },
        form: {
            name: "名称",
            name_placeholder: "请输入名称",
            data_source: "数据源",
            prop_placeholder: "搜索属性",
        },
        table: {
            property: "属性",
            value: "值",
            setting: "设置",
            filter: "请先选择一个节点",
        },
        data_source: {
            organization: "组织",
            dashboard: "园区",
            node: "节点",
        },
        prop_setting: {
            window: "时间窗口",
            color: "颜色",
            line_type: "线条",
            solid_line: "实线",
            dashed_line: "虚线",
            with_label: "带标签",
            bar_ruler: "颜色规则",
            true: "是",
            false: "否",
        },
        config_property: "配置属性",
        one_item: "第{number}项",
        delete_this: "是否确定删除此项？",
        number: {
            1: "一",
            2: "二",
            3: "三",
            4: "四",
            5: "五",
            6: "六",
            7: "七",
            8: "八",
            9: "九",
            10: "十",
        },
        chart_option: {
            chart_type: "类型",
            smooth: "线条平滑",
            background_color: "背景色",
            direction: "方向",
            min: "最小值",
            max: "最大值",
            extreme_value_annotation: "极值标注",
            avg_line: "平均线",
            grade_option: "等级选项",
            theme_color: "主题颜色",

            default: "默认",
            bar_chart: "条形图",

            pie_chart: "饼图",
            ring_chart: "环形图",
            speed_chart: "速度",
            progress_chart: "进度",
            grade_chart: "等级",
            temperature_chart: "温度",
            scoring_chart: "分数",

            name: "名称",
            config: "配置",
        },
    },
};
