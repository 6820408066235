export default [
    {
        path: "/user",
        name: "user",
        redirect: "/user/profile",
        component: () => import("@/views/user/Index.vue"),
        meta: { title: "个人中心 - User", authorization: ["admin", "member"] },
        children: [
            {
                path: "profile",
                name: "user-profile",
                component: () => import("@/views/user/Profile.vue"),
                meta: { title: "资料设置 - Profile", authorization: ["admin", "member"] },
            },
            {
                path: "organization",
                name: "user-organization",
                component: () => import("@/views/user/Organization.vue"),
                meta: { title: "工作组织 - Organization", authorization: ["admin", "member"] },
            },
        ],
    },
];
