import { defineStore } from "pinia";
import { getUserInfo } from "@/service/user";
import { getDashboard } from "@/service/overview";
import User from "@deepberry/titan-web-components/src/utils/user";
import { navigationByPermission } from "@/setting";
const themeSetting = require(`../theme/${process.env.VUE_APP_THEME}/setting.js`);

export const useUserData = defineStore({
    id: "UserData",
    state: () => ({
        userdata: {
            accountName: "", //账号
            avatar: "", //头像
            id: 0, //uid
            name: "", //昵称
            organization: { id: 0, name: "组织名称", disabled: 0, roles: [] }, //组织
            orgzs: [], //组织列表
            phoneBinded: true, //手机绑定
            phoneNumber: "", //手机号
            preference: { locale: themeSetting.locale, timezone: themeSetting.timezone }, //偏好设置
            roleId: 0, //角色id
            roles: [], //角色列表
        },
        dashboards: [], // 用户当前组织下的面板列表
        timezones: [], //时区列表

        permissions: [], //权限列表
        organizations: [] as any[], // 当前用户组织列表
        profile: {
            // 用户资料
            avatar: "",
            disabled: 0,
            id: 0,
            name: "",
            organization: { id: 0, active: 1, user_id: 0, name: "", logo: "" },
            phone_number: "",
            preference: { locale: themeSetting.locale, timezone: themeSetting.timezone }, //偏好设置
            username: "",
            token: {
                is_dev: 0,
                is_super: 0,
                oid: 0,
                uid: 0,
                version: 2,
            },
        },
    }),
    getters: {
        isAdmin(state) {
            return state.userdata.roleId <= 1;
        },
        role(state) {
            return state.userdata.roleId <= 1 ? "admin" : "member";
        },
        enabledApps(state: any) {
            return navigationByPermission[state.role];
        },
        isSuper(state) {
            return state.profile.token.is_super;
        },
        isDev(state) {
            return state.profile.token.is_dev;
        },
    },
    actions: {
        // 获取dashboard列表
        getDashboards() {
            if (!this.profile.organization.id) return;
            getDashboard({ organization_id: this.profile.organization.id }).then((res) => {
                this.dashboards = res.data.data || [];
            });
        },
        // 更新资料
        update(data) {
            this.userdata = data;
        },
        // 同步最新用户信息
        async sync() {
            // const data = await getUserInfo();
            let user_blacklist: any[] = [];
            let org_blacklist: any[] = [];
            let user_white_list: any[] = [];
            const titan_conf = sessionStorage.getItem("titan_conf");
            if (titan_conf) {
                const titan_conf_arr = JSON.parse(titan_conf);

                user_blacklist =
                    titan_conf_arr.filter((item: any) => item.key == "user_black_list")[0]?.val?.split(",") || [];
                org_blacklist =
                    titan_conf_arr.filter((item: any) => item.key == "org_black_list")[0]?.val?.split(",") || [];

                user_white_list =
                    titan_conf_arr.filter((item: any) => item.key == "user_white_list")[0]?.val?.split(",") || [];

                org_blacklist = org_blacklist.map((item) => parseInt(item));
                user_blacklist = user_blacklist.map((item) => parseInt(item));
                user_white_list = user_white_list.map((item) => parseInt(item));
            }
            if (this.profile.id) {
                // aigrow 不进行dashboard列表获取
                if (!location.href.includes("aigrow")) {
                    getDashboard({ organization_id: this.profile.organization.id }).then((res) => {
                        this.dashboards = res.data.data || [];
                    });
                }

                // 用户 id 为 8 或者 51（鹤立） 特殊处理为可以查看
                if (user_white_list.includes(this.profile.id)) {
                    return;
                }

                // 针对 id为 223 的用户或者 组织id 为 87 进行特殊处理  => 跳转至老版页面
                if (user_blacklist.includes(this.profile.id) || org_blacklist.includes(this.profile.organization.id)) {
                    User.destroy();
                    location.href = "https://io.deepberry.cn";
                }
            }
            return this.profile;
        },
    },
});
