import { createApp } from "vue";
import { createPinia } from "pinia";
import { i18n } from "@/locale/index";
import router from "@/router";
import App from "./App.vue";
import { googleMapAK } from "./setting";

import LoadScript from "vue-plugin-load-script";

import TitanUI from "@deepberry/titan-web-components";
import User from "@deepberry/titan-web-components/src/utils/user";

// 主题
const theme = process.env.VUE_APP_THEME;

// element-plus
require(`./theme/${theme}/css/element.scss`);
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// vant
import { Button, Icon, Popup, Col, Row, Dialog, Toast, Tabbar, TabbarItem } from "vant";
import "vant/lib/index.css";

// icon & style
import svgIcon from "@/plugins/svgIcon";
require(`./theme/${theme}/css/style.less`);

// setting
const themeSetting = require(`./theme/${theme}/setting.js`);
import enableDirective from "./directives/theme/enable";

// google map
import VueGoogleMaps from "@fawmi/vue-google-maps";

function init() {
    const app = createApp(App) as any;

    // 路由
    app.use(router);
    // 状态管理
    app.use(createPinia());

    // 国际化
    app.use(i18n);

    // 业务组件
    app.use(TitanUI);
    // UI组件
    app.use(ElementPlus, {
        locale: User.getLocale() == "zh-cn" ? zhCn : en,
    });
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component);
    }

    // svg icon
    app.use(svgIcon);

    // 第三方
    app.use(LoadScript);

    // google map
    app.use(VueGoogleMaps, {
        load: {
            key: googleMapAK,
            // key: "",
            libraries: "places",
        },
        autobindAllEvents: true,
    });

    // vant
    app.use(Button);
    app.use(Icon);
    app.use(Popup);
    app.use(Col);
    app.use(Row);
    app.use(Dialog);
    app.use(Toast);
    app.use(Tabbar);
    app.use(TabbarItem);

    // 全局主题变量
    app.config.globalProperties.$theme = themeSetting;

    // 主题钩子
    // TODO:可能会批量注册更多钩子
    app.directive("enable", enableDirective);

    // 挂载
    app.mount("#app");
}

init();
