export default {
    // 监控大盘
    monitor: {
        title: "Monitor Dashboard",
        add_dashboard: "Add Dashboard",
        edit_dashboard: "Edit Dashboard",
        dashboard_name: "Name",
        dashboard_placeholder: "Enter dashboard name",
        dashboard_name_required: "Please enter dashboard name",
        delete_dashboard_confirm: "Are you sure to delete this dashboard?",
        delete_chart_confirm: "Are you sure to delete this chart?",
        delete_dashboard: "Delete Dashboard",
        delete_chart: "Delete Chart",
        cross_node: "Multiple Node",

        copy_chart: "Copy Chart",
        copy_chart_new_name: "Copy Chart New Name",

        update_time: "Updated at",
        create_time: "Created at",
        minute: "Minute",
        second: "Second",
        actions: {
            edit: "Edit",
            delete: "Delete",
            copy_success: "Copy Success",
            refresh: "Refresh",
            copy: "Copy",
            export: "Export",
            fullscreen: "FullScreen",
            more: "More",
            public: "Set as public dashboard",
            public_tooltip: "Visible to all members of the organization",
            private: "Set as private dashboard",
            private_tooltip: "Only visible to yourself",
        },

        // 详情
        add_view: "Add View",
        edit_layout: "Edit Layout",
        save_layout: "Save Layout",

        times: {
            "1h": "1 Hour",
            "6h": "6 Hours",
            "12h": "12 Hours",
            "1d": "1 Day",
            "3d": "3 Days",
            "7d": "7 Days",
            custom: "Custom",
        },

        // 自动刷新
        auto_refresh: "Auto Refresh",
        by_line: "By Line{count}",
        no_data: "No Data",

        // drawer
        edit_view: "Edit View",
        chart_types: {
            line: "Line",
            bar: "Bar",
            pie: "Pie",
            area: "Area",
            dashboard: "Dashboard",
            radar: "Radar",
        },
        tabs: {
            data: "Data Options",
            chart: "Chart Options",
        },
        form: {
            name: "Name",
            name_placeholder: "Please enter chart name",
            data_source: "Source",
            prop_placeholder: "Search property",
        },
        table: {
            property: "Property",
            value: "Value",
            setting: "Setting",
            filter: "Please select a node",
        },
        data_source: {
            organization: "Organization",
            dashboard: "Dashboard",
            node: "Node",
        },
        prop_setting: {
            window: "Time Window",
            color: "Color",
            line_type: "Line",
            solid_line: "Solid Line",
            dashed_line: "Dashed Line",
            with_label: "With Label",
            bar_ruler: "Bar Ruler",
            true: "Yes",
            false: "No",
        },
        config_property: "Config Property",
        one_item: "{number} item",
        delete_this: "Are you sure to delete this?",
        number: {
            1: "First",
            2: "Second",
            3: "Third",
            4: "Fourth",
            5: "Fifth",
            6: "Sixth",
            7: "Seventh",
            8: "Eighth",
            9: "Ninth",
            10: "Tenth",
        },
        chart_option: {
            chart_type: "Type",
            smooth: "Smooth",
            background_color: "Background Color",
            direction: "Direction",
            min: "Min",
            max: "Max",
            extreme_value_annotation: "Extreme Value Annotation",
            avg_line: "Avg Line",
            grade_option: "Grade Option",
            theme_color: "Theme Color",

            default: "Default",
            bar_chart: "Bar Chart",

            pie_chart: "Pie",
            ring_chart: "Ring",
            speed_chart: "Speed",
            progress_chart: "Progress",
            grade_chart: "Grade",
            temperature_chart: "Temperature",
            scoring_chart: "Score",

            name: "Name",
            config: "Config",
        },
    },
};
