export default [
    {
        path: "/es",
        name: "es",
        redirect: "/es/stat",
        component: () => import("../views/salary/Index.vue"),
        meta: { title: "薪资管理 - Salary", authorization: ["admin", "user"] },
        children: [
            {
                path: "/es/stat",
                name: "salary-manage-stat",
                component: () => import("../views/salary/SalaryStat.vue"),
                meta: { title: "薪资统计 - Salary Stat", authorization: ["admin", "member"] },
            },
            {
                path: "/es/detail",
                name: "salary-manage-detail",
                component: () => import("../views/salary/SalaryDetail.vue"),
                meta: { title: "薪资明细 - Salary Detail", authorization: ["admin", "member"] },
            },
            {
                path: "/es/setting",
                name: "salary-manage-setting",
                component: () => import("../views/salary/SalarySetting.vue"),
                meta: { title: "薪资配置 - Salary Setting", authorization: ["admin"] },
            },
            {
                path: "/es/workers",
                name: "salary-manage-account",
                component: () => import("../views/salary/WorkersManage.vue"),
                meta: { title: "工人管理 - Workers Manage", authorization: ["admin", "member"] },
            },
        ],
    },
];
