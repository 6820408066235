import { createI18n } from "vue-i18n";
import User from "@deepberry/titan-web-components/src/utils/user";

// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
import enUs from "./lang/en-us";
import zhCn from "./lang/zh-cn";
const lang = {
    "en-us": enUs,
    "zh-cn": zhCn,
};

const dp_lang = localStorage.getItem("dp_lang");

// 2. Create i18n instance with options
const i18n = createI18n({
    locale: dp_lang || "en-us", // set locale
    fallbackLocale: "en-us", // set fallback locale
    messages: lang, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
});

export { i18n };
