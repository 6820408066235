export default {
    // 账号中心
    account: {
        // 登录
        login: {
            // 标题
            title: "综合管理系统",

            // TAB
            by_pwd: "密码登录",
            by_sms: "短信登录",

            // 表单
            username: "账号",
            password: "密码",
            phone: "手机号",
            code: "验证码",
            get_code: "获取验证码",
            btn_login: "登录",
            btn_logout: "登出",
            already_login: "您已登录。",
            btn_back: "返回",

            // 消息
            success_login: "登录成功",
        },
        logout: "退出登录",
        confirm_logout: "确认退出登录？",
        profile_settings: "资料设置",
    },
    // 个人中心
    user: {
        // 资料设置
        profile: {
            title: "用户设置",

            avatar: "头像",
            name: "昵称",

            password: "密码",
            security_settings: "安全设置",
            old_password: "当前密码",
            new_password: "新密码",
            password_not_same: "两次密码不一致",

            preference_settings: "偏好设置",
            locale: "语言",
            timezone: "时区",
        },
        // 组织切换
        organization: {
            title: "工作组织",
            change_organization: "切换组织",

            is_null: "你尚不在任何工作组织中",
        },
    },
};
