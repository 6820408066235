export default {
    // 目录
    nav: {
        home: "Home",
        insights: "Nodes",
        insight_overview: "Overview",
        insight_controller: "Controller",
        insight_conf: "Configuration",
        insight_template: "Template",
        erp: "ERP",
        enterprise: "Enterprise",
        enterprise_member: "Members",
        salary: "Scales",
        salary_stat: "Statistics",
        salary_detail: "Detail",
        salary_setting: "Setting",
        salary_account: "Account",
        workers: "Workers",
        aigrow: "AI Grow",
        aigrow_conf: "Park Configuration",
        aigrow_gardens: "Orchards",
        aigrow_ripen: "Ripen",

        overview: "Overview",
        overview_map: "Mapping",
        overview_dataview: "Dataview",
        overview_dashboard: "Dashboard",
    },
    // 头部
    header: {
        change_organization: "Change Organization",
        message: "Message",
        doc: "Documentation",
        help: "Help",
        profile_settings: "Profile",
        logout: "Logout",
        current: "Current",
        country: "Timezone",

        support: {
            title: "Support",
            before: "Pre Sales",
            after: "After Sales",
            tech: "Technical Support",
        },
    },
    // 鉴权与404等
    system: {
        message: {
            has_no_right: "You have no right to access this page.",
            back_to_previous_page: "Back to previous page",
            page_not_found: "Page not found",
            back_to_home: "GO HOME",
            upload_success: "Upload successfully",
            copy_success: "Copy successfully",
            del_success: "Deleted successfully",
            success: "Submitted successfully",
            log_again: "Please log in again",
        },
        messagebox: {
            title: "Message",
            confirm: "Confirm",
            cancel: "Cancel",
            reset: "Reset",
            delete: "Delete",
            close: "Close",
            save: "Save",
        },
        notify: {
            success_title: "Success",
            error_title: "Error",
            title: "Action",
        },
        form: {
            field_is_required: " is required",
            field_is_not_valid: " is not valid",
            update_successfully: " update successfully",
            search_placeholder: "Please input keywords",
            del: "Confirm deletion of the record?",
        },
    },
};
