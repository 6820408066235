export default [
    {
        path: "/overview",
        name: "overview",
        redirect: "/overview/dashboard",
        component: () => import("@/views/overview/Index.vue"),
        meta: { title: "总览 - Overview", authorization: ["admin", "member"] },
        children: [
            {
                path: "map",
                name: "map",
                component: () => import("@/views/overview/Map.vue"),
                meta: { title: "园区规划 - Map", authorization: ["admin", "member"] },
            },
            {
                path: "dataview",
                name: "dataview",
                component: () => import("@/views/overview/Dataview.vue"),
                meta: { title: "数据透视 - Dataview", authorization: ["admin", "member"] },
            },
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("@/views/overview/monitor/Index.vue"),
                redirect: {
                    name: "dashboard-list",
                },
                meta: { title: "监控大盘 - Dashboard", authorization: ["admin", "member"] },
                children: [
                    {
                        path: "",
                        name: "dashboard-list",
                        component: () => import("@/views/overview/monitor/List.vue"),
                        meta: { title: "监控大盘 - Monitor", authorization: ["admin", "member"] },
                    },
                    {
                        path: "detail/:uuid",
                        name: "dashboard-detail",
                        component: () => import("@/views/overview/monitor/Detail.vue"),
                        meta: { title: "监控详情 - Monitor", authorization: ["admin", "member"] },
                    },
                ],
            },
        ],
    },
];
