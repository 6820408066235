export default {
    // 企业管理
    enterprise: {
        manage: {
            title: "企业管理",
        },
        member: {
            title: "成员管理",

            // 搜索
            add_sub_account: "添加子账号",
            search_label: "搜索",
            search_placeholder: "支持姓名/电话/ID搜索",

            // 列表
            table_number: "序号",
            table_name: "姓名",
            table_accountName: "用户账号",
            table_phone: "手机号码",
            table_role: "用户角色",
            table_action: "操作",

            // 删除
            remove: "移除",
            confirm_remove: "您确定要删除? 此操作无法撤消。",
            remove_successfully: "子账号移除成功",

            // 表单
            sub_account: "子账号",
            add: "添加",
            edit: "编辑",
            type_phone: "手机号",
            type_account: "用户名",

            new_member_password_tip: "初始密码为",

            管理员: "管理员",
            普通用户: "普通用户",
            total_user: "当前共计 <b>{total}</b> 个账户",
        },
    },
};
