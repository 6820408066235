export default {
    route_name: {
        "user-profile": "资料设置",
        "user-security": "安全中心",
        "user-organization": "工作组织",
        NotAuthenticated: "无访问权限",

        // sensor
        "sensor-basic": "传感器",
        "sensor-timelapse": "时光机",
        "sensor-task": "定时任务",
        "sensor-control": "批量控制",
        "sensor-prop": "实时指标",
        "sensor-chart": "自定义图表",
        "sensor-irrigate": "灌溉助手",

        // es
        "salary-manage-stat": "薪资统计",
        "salary-manage-detail": "薪资明细",
        "salary-manage-setting": "薪资配置",
        "salary-manage-account": "工人管理",

        // overview
        map: "园区规划",
        dataview: "数据透视",
        dashboard: "监控大盘",
        "dashboard-list": "大盘列表",
        "dashboard-detail": "监控详情",

        // insights
        "insights-overview": "实时监控",
        "insights-controller": "云端调控",
        "insights-node": "节点管理",
        "insights-template": "模板管理",

        // enterprise
        "enterprise-manage-member": "成员管理",
        "enterprise-user-log": "操作日志",

        // aigrow
        "aigrow-gardens": "智能果园",
        "aigrow-ripen-upload": "图片上传",
        "aigrow-stomata": "气孔相机",
        "aigrow-ripen-albums": "相册列表",
        "aigrow-ripen-album": "相册详情",
        "aigrow-ripen-photo": "图片详情",
        "aigrow-ripen-stack": "批次详情",
        "aigrow-ripen-stack-append": "批次图片追加",

        // account
        "account-login": "登录",

        // fertilizer
        "fertilizer-index": "配肥助手",
        "fertilizer-detail": "配方",
        "fertilizer-edit": "配方编辑",
    },
};
