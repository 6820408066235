export default {
    // 账号中心
    account: {
        // 登录
        login: {
            // 标题
            title: "Integrated Management System",

            // TAB
            by_pwd: "Password Login",
            by_sms: "SMS Login",

            // 表单
            username: "Username",
            password: "Password",
            password_length_limit: "Password length is {min}-{max}",

            phone: "Phone",
            code: "Code",
            get_code: "Get",
            code_sent: "Captcha sent",
            sms_count_limit: "SMS sending times have reached the limit",
            sms_get_warning: "You have got {count} SMS Captcha, the limit is {max} per day",

            locked: "Account has been locked, please try again after 24 hours",
            error_count_limit: "You have tried to login {count} times, the account will be locked after {max} times",

            btn_login: "Login",
            btn_logout: "Logout",
            already_login: "You have already logged in.",
            btn_back: "Back",

            // 消息
            success_login: "Login Success",
        },
        logout: "Logout",
        confirm_logout: "Are you sure to logout?",
        profile_settings: "Profile",
    },
    // 个人中心
    user: {
        // 资料设置
        profile: {
            title: "Profile",

            avatar: "Avatar",
            name: "Nickname",

            password: "Password",
            security_settings: "Security Settings",
            old_password: "Old Password",
            new_password: "New Password",
            password_not_same: "Two passwords are inconsistent",

            preference_settings: "Preference Settings",
            locale: "Language",
            timezone: "Timezone",
        },
        // 组织切换
        organization: {
            title: "Organization",

            is_null: "You are not in any organization.",
        },
    },
};
