export default {
    // 企业管理
    enterprise: {
        manage: {
            title: "Enterprise Management",
        },
        member: {
            title: "Member",

            // 搜索
            add_sub_account: "Create Account",
            search_label: "Search",
            search_placeholder: "Please input keywords",

            // 列表
            table_number: "ID",
            table_name: "Name",
            table_accountName: "Account",
            table_phone: "Phone",
            table_role: "Role",
            table_action: "Operation",

            // 删除
            remove: "Remove",
            confirm_remove: "Are you sure to remove this account?",
            remove_successfully: "The Account removed successfully",

            // 表单
            sub_account: "Sub Account",
            add: "Add",
            edit: "Edit",
            type_phone: "Phone",
            type_account: "Account",

            new_member_password_tip: "The password of new member is ",

            管理员: "Administrator",
            普通用户: "User",
            total_user: "Total <b>{total}</b> accounts",
        },
    },
};
