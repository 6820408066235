export default [
    {
        path: "/insights",
        name: "insights",
        redirect: "/insights/nodes",
        component: () => import("@/views/insights/Index.vue"),
        meta: { title: "智控平台 - Insights", authorization: ["admin", "member"] },
        children: [
            {
                path: "overview",
                name: "insights-overview",
                component: () => import("@/views/insights/Overview.vue"),
                meta: { title: "实时监控 - Overview", authorization: ["admin", "member"] },
            },
            {
                path: "nodes",
                name: "insights-controller",
                component: () => import("@/views/insights/Nodes.vue"),
                meta: { title: "云端调控 - Node Management", authorization: ["admin", "member"] },
            },
            {
                path: "conf",
                name: "insights-node",
                component: () => import("@/views/insights/Conf.vue"),
                meta: { title: "节点配置 - Dashboard Configuration", authorization: ["admin", "member"] },
            },
            {
                path: "template",
                name: "insights-template",
                component: () => import("@/views/insights/Template.vue"),
                meta: { title: "模板管理 - Template Configuration", authorization: ["admin", "member"] },
            },
        ],
    },
];
