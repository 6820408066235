export default {
    // 目录
    nav: {
        home: "首页",
        insights: "节点管理",
        insight_overview: "实时监控",
        insight_controller: "云端调控",
        insight_conf: "节点配置",
        insight_template: "模板管理",
        erp: "ERP系统",
        enterprise: "企业管理",
        enterprise_member: "成员管理",
        salary: "电子秤",
        salary_stat: "工资统计",
        salary_detail: "工资明细",
        salary_setting: "工资设置",
        salary_account: "采摘账号",
        workers: "工人管理",
        aigrow: "AI Grow",
        aigrow_conf: "果园配置",
        aigrow_gardens: "果园概览",
        aigrow_ripen: "果熟识别",

        overview: "总览",
        overview_map: "园区规划",
        overview_dataview: "数据透视",
        overview_dashboard: "监控大盘",
    },
    // 头部
    header: {
        change_organization: "切换组织",
        message: "消息中心",
        profile_settings: "资料设置",
        doc: "文档中心",
        logout: "退出登录",
        help: "帮助中心",
        current: "当前",
        country: "时区",

        support: {
            title: "支持",
            before: "售前咨询",
            after: "售后支持",
            tech: "技术支持",
        },
    },
    // 鉴权与404等
    system: {
        message: {
            has_no_right: "您没有该页面的访问权限.",
            back_to_previous_page: "返回上一页",
            page_not_found: "抱歉，您访问的页面不存在",
            back_to_home: "返回首页",
            upload_success: "上传成功",
            copy_success: "复制成功",
            del_success: "删除成功",
            success: "提交成功",
            log_again: "请重新登录",
        },
        messagebox: {
            title: "消息",
            confirm: "确认",
            cancel: "取消",
            reset: "重置",
            delete: "删除",
            close: "关闭",
            save: "保存",
        },
        notify: {
            success_title: "操作成功",
            error_title: "操作失败",
            title: "操作",
        },
        form: {
            field_is_required: "不能为空",
            field_is_not_valid: "格式不正确",
            update_successfully: "更新成功",
            search_placeholder: "请输入关键词搜索",
            del: "确认删除该条记录？",
        },
    },
};
