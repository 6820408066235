export default [
    {
        path: "/enterprise",
        name: "enterprise",
        redirect: "/enterprise/member",
        component: () => import("@/views/enterprise/Index.vue"),
        meta: { title: "企业管理 - Enterprise", authorization: ["admin"] },
        children: [
            {
                path: "member",
                name: "enterprise-manage-member",
                component: () => import("@/views/enterprise/Members.vue"),
                meta: { title: "成员管理 - Member Management", authorization: ["admin"] },
            },
        ],
    },
];
