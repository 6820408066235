import common from "./common";
import account from "./account";
import enterprise from "./enterprise";
import insights from "./insights";
import sensor from "./sensor";
import aigrow from "./aigrow";
import es from "./es";
import help from "./help";
import title from "./title";
import overview from "./overview";

export default {
    ...common,
    ...account,
    ...enterprise,
    ...insights,
    ...sensor,
    ...aigrow,
    ...es,
    ...help,
    ...title,
    ...overview,
};
