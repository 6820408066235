export default [
    {
        path: "/aigrow",
        name: "aigrow",
        redirect: "/aigrow/gardens",
        component: () => import("@/views/aigrow/Index.vue"),
        meta: { title: "智能果园 - Aigrow", authorization: ["admin", "member"] },
        children: [
            {
                path: "gardens",
                name: "aigrow-gardens",
                component: () => import("@/views/aigrow/garden/Index.vue"),
                meta: { title: "园区概览 - Gardens", authorization: ["admin", "member"] },
            },
            {
                path: "ripen",
                name: "aigrow-ripen",
                redirect: {
                    name: "aigrow-ripen-albums",
                },
                component: () => import("@/views/aigrow/ripen/Index.vue"),
                meta: { title: "果熟识别 - Ripen", authorization: ["admin", "member"] },
                children: [
                    {
                        path: "albums",
                        name: "aigrow-ripen-albums",
                        component: () => import("@/views/aigrow/ripen/AlbumList.vue"),
                        meta: { title: "相册列表 - Ripen", authorization: ["admin", "member"] },
                    },
                    {
                        path: "album/:id(\\d+)?",
                        name: "aigrow-ripen-album",
                        component: () => import("@/views/aigrow/ripen/AlbumSingle.vue"),
                        meta: { title: "相册详情", authorization: ["admin", "member"] },
                    },
                    {
                        path: "album/:album_id(\\d+)/stack/:stack_id(\\d+)/photo/:photo_uuid",
                        name: "aigrow-ripen-photo",
                        component: () => import("@/views/aigrow/ripen/AlbumPhoto.vue"),
                        meta: { title: "图片详情 - Ripen", authorization: ["admin", "member"] },
                    },
                    {
                        path: "album/:album_id(\\d+)/stack/:stack_id(\\d+)",
                        name: "aigrow-ripen-stack",
                        component: () => import("@/views/aigrow/ripen/StackSingle.vue"),
                        meta: { title: "批次详情 - Ripen", authorization: ["admin", "member"] },
                    },
                    {
                        path: "album/:album_id(\\d+)/stack/:stack_id(\\d+)/append",
                        name: "aigrow-ripen-stack-append",
                        component: () => import("@/views/aigrow/ripen/AlbumUploadAppend.vue"),
                        meta: {
                            title: "批次图片追加 - Ripen - Upload",
                            authorization: ["admin", "member"],
                        },
                    },
                ],
            },
            {
                path: "ripen/upload",
                name: "aigrow-ripen-upload",
                component: () => import("@/views/aigrow/ripen/AlbumUpload.vue"),
                meta: {
                    title: "上传图片 - Ripen - Upload",
                    authorization: ["admin", "member"],
                },
            },
        ],
    },
];
