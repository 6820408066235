import { $cms } from "@/utils/api";

// 节点分组的CURD
export function getNodeGroup(params) {
    return $cms().get(`/titan/node/nodegroup`, {
        params,
    });
}
export function createNodeGroup(data) {
    return $cms().post(`/titan/node/nodegroup`, data);
}
export function updateNodeGroup(id, data) {
    return $cms().put(`/titan/node/nodegroup/${id}`, data);
}
export function updateNodeGroups(data) {
    return $cms().put(`/titan/node/nodegroups`, data);
}
export function delNodeGroup(id) {
    return $cms().delete(`/titan/node/nodegroup/${id}`);
}
// 创建node信息
export function createNode(data) {
    return $cms().post(`/titan/node`, data);
}
// 更新node信息
export function updateNode(id, data) {
    return $cms().put(`/titan/node/${id}`, data);
}

// 获取node信息
export function getNode(id: Number) {
    if (!id) return;
    return $cms().get(`/titan/node/${id}`);
}

// 获取dashboard信息
export function getDashboard(params) {
    return $cms().get(`/titan/dashboard`, {
        params,
    });
}

// 节点排序
export function sortNode(data) {
    return $cms().put(`/titan/node/order`, data);
}

// 获取专家预设列表
export function getPreset(params) {
    return $cms().get(`/titan/node/indicator/preset`, {
        params,
    });
}

// 更新节点指标
export function updateNodeIndicator(id, data) {
    return $cms().post(`/titan/node/${id}/indicator`, data);
}

// 获取节点指标
export function getNodeIndicator(id) {
    return $cms().get(`/titan/node/${id}/indicator`);
}
