export default {
    // 园区管理
    insights: {
        nodes: {
            current_node: "Current Node",
            selection_node: "Select Node",
            title: "Node",
            manage: "Nodes",

            // 筛选
            sort_btn_start: "Start Order",
            sort_btn_stop: "Stop Order",

            // 表单
            add: "Add Node",
            edit: "Edit Node",
            dashboard: "Dashboard",
            name: "Name",
            remark: "Remark",
            dashboard_placeholder: "Please select dashboard",
            name_placeholder: "Please input name",
            remark_placeholder: "Please input remark",
            search_placeholder: "Please input keywords",

            // 设置
            delete: "Delete",
            confirm_delete: "Are you sure to delete this node?",
            confirm_delete_prefix: "Are you sure to delete ",
            confirm_delete_suffix: " ?",
            upload_template: "Upload Template",
            download_template: "Download Template",
            empty_group: "No group",
            to_create: "Create A Group",

            // 列表
            untitled: "Untitled",
            empty_list: "No data",

            // 操作
            prop: "Properties",
            chart: "Charts",
            params: "Params",
            cron: "Tasks",
            device: "Devices",
            node_template: "Template",
            settings: "Settings",
            geo: "Geography",
            irrigate: "Irrigation",
        },
        dashboard: {
            current_dashboard: "Current",
            selection_dashboard: "Select Dashboard",
            drawer_title: "Dashboard",

            dashboard: "Dashboard",
            add: "Add",
            edit: "Edit",
            delete: "Delete",
            confirm_delete: "Are you sure to delete this dashboard?",
            add_dashboard: "Add Dashboard",

            dashboards: "Dashboards",
            nodes: "Nodes",

            search_placeholder: "Search Dashboard",

            // form
            name: "Name",
            name_placeholder: "Please input name",
            remark: "Remark",
            remark_placeholder: "Please input remark",

            auth: "Authorize",
            grant: "Grant",
            select_user: "Select",
            unknown: "Unknown",
            remove: "Remove",
            confirm_remove: "Are you sure to remove this user?",
            invalid: "Invalid",
        },
        panel: {
            add: "Add",
            edit: "Edit",
            start: "Start",
            stop: "Stop",
            del: "Delete",
            tips: "Tips",
            copy: "Copy",
            enabled: "Enable",
            disabled: "Disable",
            action: "Operation",
            note: "Remark",
            ok: "OK",
            cancel: "Cancel",
            prop: {
                add: "Add Attribute",
                edit: "Edit Attribute",
                attribute_type: "Attribute Type",
                attribute_type_placeholder: "Please select attribute type",
                attribute_name_exist: "Attribute name already exists",
                attribute_name: "Attribute Name",
                attribute_name_placeholder: "Please enter the attribute name",
                attribute_display_name: "Display Name",
                attribute_display_name_placeholder: "Please enter the display name of the attribute",
                attribute_value: "Attribute Value",
                attribute_value_placeholder: "Please enter the attribute value",
                data_type: "Date Type",
                view_mode: "View Mode",
                view_mode_placeholder: "Please select view mode",
                view_meta: "View Meta",
                view_meta_min: "Min",
                view_meta_max: "Max",
                view_meta_fitMin: "FitMin",
                view_meta_fitMax: "FitMax",
                view_meta_required: "Please enter the view meta",
                attribute_type_input: "Input",
                attribute_type_switch: "Switch",
                attribute_type_camera: "Camera",
                attribute_type_radio: "Status",
                unit: "Unit",
                unit_placeholder: "Please enter the unit",
                icon: "Icon",
                icon_placeholder: "Select Icon",
                icon_search_placeholder: "Search Icon",
                panel_display: "Display",
                allow_operation: "Allow Operation",
                data_persistence: "Data Persistence",
                robotIp: "Robot IP",
                cameraIp: "Live URI",
                del_message: "Are you sure you want to delete this attribute? This action can not be undone.",
                setting: "Data Setting",
                empty_property: "No attribute",

                custom: "Custom",

                sunlight_warning: "If this property is enabled, be sure to set the geographic information.",

                group: "Group",
                group_placeholder: "Please select group",
            },
            parameter: {
                add: "Add Parameter",
                parameter: "Parameter",
                name: "Parameter Name",
                alias: "Parameter Alias",
                type: "Parameter Type",
                types: {
                    string: "String",
                    number: "Number",
                    time: "Time",
                },
                value: "Parameter Value",
                time: "Time",
                string: "String",
                number: "Number",
                error_name: "Please enter the parameter name",
                error_alias: "Please enter the display name of the parameter",
                error_value_number: "Please enter the numeric parameter value",
                error_value_text: "Please enter the text parameter value",
                error_value_time: "Please enter the time parameter value",
                save_success: "Parameters saved successfully",
                operation_success: "Parameter operation successfully",
                del_message: "Are you sure you want to delete this parameter? This action can not be undone.",
                delete_success: "Parameter deleted successfully",
                launch_success: "The parameters were sent successfully",
                sure: "Are you sure to send down all configuration parameters?",
                empty: "No parameters",
                content:
                    "No device is connected and cannot be issued immediately. Submitted changes are saved on the server side and the server will automatically issue the submitted changes after the device is connected.",

                // 状态组件
                status: {
                    close: "Close",
                    open: "Open",
                    stop: "Stop",
                },
            },
            tasks: {
                add: "Add Task",
                task: "Task",
                name: "Task Name",
                type: "Timing Syntax Type",
                daily: "Timing Settings (daily)",
                basic: "Basic",
                advanced: "Advanced",
                cronExps: "Cron Expression",
                desc: "Description",
                desc_placeholder: "Please enter the description",
                add_micro_task: "Add Micro Task",
                cron_tip: "When you set basic mode, all the rules will execute every day.",
                trigger: "Trigger Immediately",
                empty: "No task",
                callback: "Operation Callback",
                errorCallback: "Please enter the operation callback function",
                errorCronExps: "Please enter the cron expression",
                del_message: "Are you sure you want to delete this task? This action can not be undone.",
            },
            cron: {
                Seconds: {
                    name: "Seconds",
                    every: "Every second",
                    interval: ["Every", "second(s) starting at second"],
                    specific: "Specific second (choose one or many)",
                    cycle: ["Every second between second", "and second"],
                },
                Minutes: {
                    name: "Minutes",
                    every: "Every minute",
                    interval: ["Every", "minute(s) starting at minute"],
                    specific: "Specific minute (choose one or many)",
                    cycle: ["Every minute between minute", "and minute"],
                },
                Hours: {
                    name: "Hours",
                    every: "Every hour",
                    interval: ["Every", "hour(s) starting at hour"],
                    specific: "Specific hour (choose one or many)",
                    cycle: ["Every hour between hour", "and hour"],
                },
                Day: {
                    name: "Day",
                    every: "Every day",
                    intervalWeek: ["Every", "day(s) starting on"],
                    intervalDay: ["Every", "day(s) starting at the", "of the month"],
                    specificWeek: "Specific day of week (choose one or many)",
                    specificDay: "Specific day of month (choose one or many)",
                    lastDay: "On the last day of the month",
                    lastWeekday: "On the last weekday of the month",
                    lastWeek: ["On the last", " of the month"],
                    beforeEndMonth: ["day(s) before the end of the month"],
                    nearestWeekday: ["Nearest weekday (Monday to Friday) to the", "of the month"],
                    someWeekday: ["On the", "of the month"],
                },
                Week: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                Month: {
                    name: "Month",
                    every: "Every month",
                    interval: ["Every", "month(s) starting in"],
                    specific: "Specific month (choose one or many)",
                    cycle: ["Every month between", "and"],
                },
            },
            device: {
                current_devices: "Current Devices",
                device_history: "Device History",

                device_id: "Device ID",
                device_alias: "Device Alias",
                device_network: "Network Type",
                device_status: "Status",
                operation: "Operation",

                unbind: "Unbind",
                program: "Program",

                bind_success: "Bind successfully",
                bind_device: "Bind Device",
                bind_btn: "Bind",
                bind_placeholder: "Please input device ID",

                unbind_message: "Are you sure to unbind this device?",
                unbind_success: "Unbind successfully",
                rebind: "Rebind",
                no_history: "No Historical device",

                online: "Online",
                offline: "Offline",
                script_placeholder: "Please input script",
                script_library: "Script Library",
                script_library_logs: "Script Library Logs",
                more: "More",

                device_name: "Device Name",
                hardware_version: "Hardware Version",
                firmware_version: "Firmware Version",
                network_type: "Network Type",
                current_memory: "Current Memory",
                history_memory: "History Memory",
                script_capacity: "Script Capacity",
                firmware_update: "Firmware Update",
                copy_success: "Copy successfully",
                del_script: "Delete Script",
                del_success: "Delete successfully",
                confirm_label: "Confirm",

                file_select: "Select File",
                save: "Save",
                rollback: "Rollback",
                script_empty: "Script cannot be empty",
                save_success: "Save successfully",
                rollback_success: "Rollback successfully",
                install_success: "Install successfully",
                add_script: "Add Script",
                add: "Add",
                tips: "Tips",
                script_name_enter: "Please enter the script name",
                script_name_validate: "Please enter the script name",
                script_name_exist: "The script name already exists",
                tabs_alert: "Please select the script you want to view from the left column",

                device_info: "Device Info",
                copy_device_id: "Copy Device ID",
                install: "Install",

                generate_code: "Code Generation",
                loading_code_templates: "Import Template",
                save_code: "Save as Template",
                save_script: "Save",
                edit_success: "Edit successfully",
                enter_code_name: "Please enter the template name",
                enter_group_name: "Please enter the group name",
                select_group: "Please select a group",
                code_templates: "Code Templates",
                add_group: "Add Group",
                general_templates: "General templates",
                custom_templates: "Custom templates",
                custom_group: "Custom Groups",
                group: "Group",
                quick_Add: "Save Code Template",
                change_name: "Change the script name",
                save_directly: "Save directly",
                no_code: "No corresponding code module",
                group_name_empty: "Group name cannot be empty",
                code_name_validate: "Please enter the code name",

                search_xterm: "Search",
            },
            chart: {
                start: "Start Date",
                end: "End Date",
                to: "To",
                last_7_days: "L7D",
                last_3_days: "L3D",
                yesterday: "Yesterday",
                today: "Today",
                last_month: "LM",
                last_3_months: "L3M",
                last_6_months: "L6M",
                last_24_hours: "L24H",
                search: "Search",
                download: "Export CSV",
                date_error: "Please select start date or end date",
                data_error: "Please select to download data",
            },
            template: {
                import: "Import",
                import_confirm_title: "Confirm Import",
                import_tips:
                    "This operation will modify existing node configuration options and may cause all functions to be unavailable. Non-technical personnel should not perform this operation. Please confirm that you are aware that this operation is irreversible and cannot be undone.",
                import_mode: "Path",
                import_mode_template: "Import Template",
                import_mode_file: "Import File",
                select_template: "Template",
                select: "- Select -",
                select_file: "Select File",
                template_upload_tip: "Drop file here or click to upload",
                template_upload_limit: "Only json file is supported",
                template_module: "Modules",
                module: "Modules",
                modules: {
                    properties: "Properties",
                    parameters: "Parameters",
                    crontabs: "Timed Task",
                },
                mode: "Mode",
                merge: "Merge",
                coverage: "Coverage",
                reset: "Reset",

                export: "Export",
                export_mode: "Path",
                export_mode_template: "Save as Template",
                export_mode_file: "Export to Local",
                template_group: "Group",
                template_name: "Name",
                file_name: "Name",
                download: "Download",
                save: "Save",

                add_group: "Add Group",
                group_config: "Group Config",
                all: "All",
                group_name: "Group Name",
                temp_placeholder: "Please enter the template name",
                name_error: "Please enter the template name",
                edit_group: "Edit",
                del_group: "Delete",

                from: "From",
                updated_at: "Updated At",
                temp_search_placeholder: "Search by name",
                operation: "Operation",
                template_type: "Type",
                public_group: "Public",
                private_group: "Private",
                public_template: "Public",
                private_template: "Private",
                edit_template: "Edit",
                view_template: "View",
                group: "Group",
                creator: "Creator",
                organization: "Organization",
                template_content: "Content",
                empty_group: "No Group",
                group_switch: "Templates",
                create_template: "Create",
            },
            setting: {
                // 地理
                locate: "Locate",
                cancel: "Cancel",
                geo_reset: "Reset Geo?",
                coordinate: "Coordinate",
                current_coordinate: "Local Coordinates",

                // 分组
                group: "Group",
                no_group: "No Group",
                search_node: "Search",
                setting: "Setting",
                empty_node: "No Node",
                group_name_error: "Group name cannot be empty",
                group_name_placeholder: "Please enter the group name",
                group_empty: "No Group",

                no_match: "No Matched Nodes",
                other_dashboard_match: "Other Dashboard Matched",
                dashboard: "Dashboard",

                base: "Base",
                plugin_extend: "Plugin Extend",

                // sigrow
                sigrow_api_placeholder: "Please enter the Sigrow API Key (Optional)",
                sigrow_central_placeholder: "Please enter the Sigrow Central ID",
            },
            // 灌溉任务
            irrigate: {
                alert: "New Task is Syncing...",
                task_list: "Task List",
                add_task: "Add",

                // 列表字段
                index: "Index",
                name: "Name",
                type: "Type",
                duration: "Duration",
                irrigate_duration: "Irrigate Duration(Area)",
                status: "Status",
                operation: "Operation",

                timing: "Mix-Timing",
                integral: "Mix-Integral",
                fertilizer: "Storage-Fertilizer",
                storage_timing: "Storage-Timing",
                storage_integral: "Storage-Integral",

                trigger: "Trigger",
                edit: "Edit",
                delete: "Delete",
                delete_confirm: "Confirm Delete?",
                copy: "Copy",
                trigger_success: "Trigger Success",
                trigger_failed: "Trigger Failed",
                enabled_failed: "Enabled Failed",
                // 有一个任务存在冲突
                enabled_conflict: "Conflict With Task【{name}】, Time【{time}】",
                max_should_be_greater_than_min: "Max should be greater than Min",
                self_conflict: "Time Conflicts With Self, Time【{time}】",

                // 弹窗内容
                dialog_edit_title: "Edit Task",
                dialog_add_title: "Add Task",
                dialog_copy_title: "Copy Task",
                name_placeholder: "Please enter the task name",

                per_duration: "Duration",
                // 定时灌溉
                timing_params: "Timing Parameters",
                time: "Time",
                time_placeholder: "Please select the time",
                duration_text: "{time}",
                irrigate_to_drainage: "Irrigate to Drainage",
                minute: "Minutes",

                // 公共参数
                common_params: "Common Parameters",
                target_ec: "Target EC",
                target_ec_placeholder: "Please enter the target EC",
                ec_pump_speed_ratio: "PSR",
                ec_pump_speed_ratio_full: "EC Pump Speed Ratio（B Pump Speed / A Pump Speed）",
                ec_pump_speed_ratio_placeholder: "Please enter the EC Pump Speed Ratio",
                ph_pump_speed: "PPR",
                ph_pump_speed_full: "PH Pump Speed",
                ph_pump_speed_placeholder: "Please enter the pH Pump Speed",
                irrigate_area: "LRAs",
                irrigate_area_full: "Lateral Move Irrigation Areas",
                io_text: "No.{io} Valve",
                add_io: "Add",

                // 积分灌溉
                integral_params: "Integral Parameters",
                integral_duration: "Integral Duration",
                integral_duration_text: "To",
                integral_threshold: "Integral Threshold",
                integral_threshold_placeholder: "Please enter the integral threshold",
                max_integral_interval: "Max Integral Interval",
                first_irrigate_duration: "First Irrigate Duration",
                first_irrigate_duration_placeholder: "Please enter the first irrigate duration",
                first_irrigate_ec: "First Target EC",
                first_irrigate_ec_placeholder: "Please enter the first target EC",
                // 配肥
                fertilizer_params: "Fertilizer Parameters",
                fertilizer_type: "Fertilizer Type",
                timing_fertilizer: "Timing Fertilizer",
                fertilizer_time: "Fertilizer Time",
                fertilizer_duration: "Fertilizer Duration",
                fertilizer_duration_placeholder: "Please select the fertilizer duration",
                liquid_level_fertilizer: "Liquid Level Fertilizer",
                start_liquid_level: "Start Liquid Level",
                stop_liquid_level: "Stop Liquid Level",
                meter: "Meter",
            },
        },
        overview: {
            basic: "Basic",
            timelapse: "timelapse",
            task: "Task",
            control: "Control",
            follow: "Follow",
            chart: "Charts",
            irrigate: "Irrigation",
            stomata_camera: "Stomata Camera",

            indicator: {
                title: "Readings",
                chart: "Charts",
                empty: "No Data",

                par: "PAR",
                par_integral: "PAR Integral",
                co2: "co2",
                air_temperature: "Air Temperature",
                dew_point: "Dew Point",
                frost_point: "Frost Point",
                air_humidity: "Air Humidity",
                absolute_humidity: "Absolute Humidity",
                VPD: "VPD",
                vpd: "VPD",
                soil_ec: "Soil EC",
                soil_temperature: "Soil Temperature",
                soil_humidity: "Soil Humidity",
                soil_water: "Soil Water",
                pore_ec: "Pore EC",
                discharge_ratio: "Discharge Ratio",
                drainage_ec: "Drainage EC",
                radiation: "Radiation",
            },

            indicator_types: {
                light: "Light",
                air: "Air",
                heat: "Heat",
                water: "Water",
                soil: "Soil",
                root: "Root",
                plant: "Plant",
            },

            outdoor: {
                OAT: "Temperature",
                Td: "Dew Point",
                RH: "Humidity",
                HPD: "Precipitation",
                Pa: "Pressure",
                WS: "Wind Speed",
                wind_direction: "Wind Direction",
                cloud: "Cloudage",
                title: "Outdoor Env",
                no_geo: "No node geographic information has been set",
                click_to_configure: "click to configure",
                next_24_hour: "Next 24 hours",
            },

            device: {
                title: "Device",
                advanced_configuration: "Advanced Configuration",
                empty_device: "No device",
            },

            controls: {
                global: "Global",
                global_warning: "Please operate carefully",
                untitled: "Untitled",
                selected_all: "Select Group",
                batch_control: "Batch Control",
                empty: "No Switch",
            },
            tasks: {
                title: "Tasks",
                name_edit: "Edit",
                more: "{count} total",
                status: "Status",
                on: "On",
                off: "Off",
                trigger: "Trigger",
                coding: "Coding",
                template: "Template",
                desc: "Description",
                name_placeholder: "Please enter the task name",
                cron_tip: "Multiple rules can be set up simultaneously via microtasks",
                empty_text: "No task",
                cron_title: "Cron Setting",
                untitled: "Untitled",
                success: "Success",
                unknown_error: "Unknown Error",
                all: "All",

                save_as_temp: "Save as Template",
                empty_template: "No template",
                templates: {
                    title: "Templates",
                    public: "Public",
                    user: "Private",
                    no_group: "Not grouped",
                    insert: "Import",
                    description: "Description",
                    cron_exps: "Cron",
                    no_template: "No Template",
                    to_create: "Go to Create Template",
                    join_group: "Join Group",
                    duplicate_names: "Duplicate timed task names",
                    enter_name: "Please enter the name of the timed task",
                },
                logs: {
                    title: "Logs",
                    log_status: "Status",
                    log_time: "Time",
                    log_detail: "Detail",
                    task_name: "Name",
                    empty_log: "No log",
                    empty: "No log",
                    execution_duration: "Execution Duration",
                    remark: "Remark",
                    edit_remark: "Edit Remark",

                    start: "Start",
                    end: "End",

                    SUCCESS: "Success",
                    FAILED: "Failed",
                    unknown: "Unknown",
                    undefined: "Unknown",
                },
            },

            irrigates: {
                summary: "Summary",
                crop_balance: "Crop Balance",
                water_change: "Water Change",
                sidebar_title: "Real-time data",
                sidebar_null: "No data",

                // summary
                irrigate: "Irrigation",
                drain: "Drainage",
                discharge_ratio: "Drainage Ratio",
                irrigation_efficiency: "Irrigation Efficiency",

                total_irrigate: "Total Irrigation",
                instant_irrigate: "Instant Irrigation",

                // crop balance
                energy: "Energy",
                biomass_accumulation: "Biomass Accumulation",
            },

            preset: {
                title: "Preset",
                preset_plan: "Preset Plan",
            },

            // 气孔相机
            stomata: {
                dli: "Day Light Integral",
                g_water: "Absolute Humidity",
                vbat: "Battery percentage",
                par: "PAR",
                temp: "Air Temperature",
                humid: "Relative Humidity",
                t_dew: "Dew Point Temperature",
                t_leaf: "Dry Leaf Temperature",
                vpd: "VPD Air",
                history: "History",

                parameter: "Parameter Chart",
                prev: "Previous",
                next: "Next",
                auto: "Auto",
                distance: "Set Distance",
                distance_placeholder: "Please enter the distance",
                next_soon: "Next soon",
                stop: "Stop",
                current_time: "Current Time",
                prev_time: "Prev",
                next_time: "Next",
                empty: "No Photos",
                camera_snapshot: "Camera Snapshot",

                camera_config: "Camera Configuration",
                dry_leaf_config: "Dry Leaf Configuration",
                edit_dry_leaf_position: "Edit Dry Leaf Position",
                point_name_required: "Please enter the point name",
                point_name: "Point Name",
                point_x_required: "Please enter the X coordinate",
                point_x: "X",
                point_y_required: "Please enter the Y coordinate",
                point_y: "Y",
                camera_placeholder: "Please type the camera configuration",

                // 原图
                original: "Original",
                // 温度
                temperature: "Temperature",
                // 气孔
                stomata: "Stomata",
                temperature_error: "Error",
            },
        },
        conf: {
            group: "Node Grouping",
            create_node: "Create Node",
            create_dashboard: "Create Dashboard",
            group_manage: "Group manage",
            permissions: "Permissions granted",
            edit_dashboard: "Edit Dashboard",
            delete_dashboard: "Delete Dashboard",
            no_node: "The current grouping does not have any nodes",
            duplicate_group_names: "Duplicate group names",
            dashboard_switch: "Dashboard",

            total: "(<b>{total}</b> Nodes)",
        },
        manage: {
            title: "Nodes",
        },

        // 公共分组组件
        group: {
            add: "Add",
            search_placeholder: "Please enter the name of the group",
            title_placeholder: "Group Name",
            title: "Group",
            operation: "Operation",
        },
    },
};
